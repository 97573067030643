/* You can add global styles to this file, and also import other style files */

body {
  background-color: #f9f9f9;
}

.hidden {
  display: none;
}

.btn-info{
  background-color: #009fdf;
}
